<template>
  <v-dialog v-model="dialog" max-width="600" persistent transition="dialog-bottom-transition">
    <v-card tile>
      <v-toolbar flat dark color="primary">
        <!-- close btn -->
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <!-- title -->
        <v-toolbar-title v-if="server.connection"
          >Filtros: {{ server.connection.database !== null ? server.connection.database.toUpperCase() : '-' }}</v-toolbar-title
        >
        <v-spacer />

        <!-- add field modal -->
        <v-toolbar-items>
          <v-btn dark text @click="addFilterField = true">
            Adicionar
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <!-- filters -->
      <v-container v-if="server.configuration">
        <v-row justify="center" align-content="center">
          <v-chip
            v-for="(value, index) in server.configuration.filters.units"
            :key="index"
            close
            class="ma-1"
            color="success"
            label
            text-color="white"
            @click:close="deleteManager(value)"
          >
            {{ value }}
          </v-chip>
          <change-dialog v-if="changesDialogDelete" :dialog.sync="changesDialogDelete" :message.sync="changesMessage" @proceed="removeFilter(key)" />
        </v-row>
      </v-container>
    </v-card>

    <!-- add fields modal -->
    <v-dialog v-if="server.configuration" v-model="addFilterField" width="660">
      <v-card class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title class="headline"><v-icon class="mr-2">mdi-filter</v-icon> Adicionar Filtro</v-card-title>
          <v-text-field v-model="filter" class="mx-2" :rules="filterRules" label="Filtro" outlined />
          <v-divider />
        </v-form>
        <btn-save-cancel
          :save-btn-disable="!filter || !filter.trim()"
          props-class="pa-2 mt-4"
          @onSave="saveManager(filter)"
          @onCancel="addFilterField = false"
        />
        <change-dialog :dialog.sync="changesDialogInsert" :message.sync="changesMessage" @proceed="addFilter(filter)" />
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { MUTATION_REMOVE_FILTER, MUTATE_ADD_UNIT } from '@/modules/accounts/graphql/index'

export default {
  components: {
    BtnSaveCancel: () => import('@/components/base/BtnSaveCancel'),
    ChangeDialog: () => import('@/components/base/ChangeDialog')
  },
  props: {
    dialog: Boolean,
    server: {
      type: Object,
      default: () => ({})
    },
    isDeploying: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    addFilterField: false,
    filter: '',
    valid: false,
    filterRules: [v => !!v.trim() || 'Nome do filtro é obrigatório'],
    changesDialogInsert: false,
    changesDialogDelete: false,
    changesMessage: '',
    key: ''
  }),
  methods: {
    async removeFilter(key) {
      const keyIndex = this.server?.configuration?.filters?.units?.indexOf(key)
      this.$setLogMessage(this.changesMessage)
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_REMOVE_FILTER,
          variables: {
            id: this.server.accountId,
            index: this.server.index,
            key: key.toString()
          }
        })

        this.server.configuration.filters.units.splice(keyIndex, 1)
        this.$snackbar({ message: 'Filtro removido', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err)
        this.$snackbar({ message: 'Falha ao remover filtro', snackbarColor: '#F44336' })
      }
    },
    async addFilter(field) {
      if (!this.$refs.form.validate()) return this.$snackbar({ message: 'Erro ao validar campos', snackbarColor: '#F44336' })

      this.$setLogMessage(this.changesMessage)
      const params = {
        id: this.server.accountId,
        server_index: this.server.index,
        unit: field.toString()
      }
      try {
        await this.$apollo.mutate({
          mutation: MUTATE_ADD_UNIT,
          variables: params
        })
        this.server.configuration.filters.units.push(field)
        this.addFilterField = false
        this.$snackbar({ message: 'Filtro salvo', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao salvar filtro', snackbarColor: '#F44336' })
      }
      this.filter = ''
    },
    saveManager(filter) {
      if (this.isDeploying) return this.addFilter(filter)
      this.changesDialogInsert = true
    },
    deleteManager(key) {
      this.key = key
      if (this.isDeploying) return this.removeFilter(key)
      this.changesDialogDelete = true
    }
  }
}
</script>
